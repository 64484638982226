import fetch from './index'

export function getFactoriesList(params): any {
  return fetch.get('/eps/factories/list', { params })
}
// 获取全部工厂
export function getAllFactoriesList(): any {
  return fetch.get('/eps/factories/all/list')
}
// 工厂排序
export function putFactorySort(params): any {
  return fetch.put('/eps/factories/sort', params)
}
 
export function delFactory(id) {
  return fetch.delete(`/eps/factories/${id}`)
}

export function addFactory(params) {
  return fetch.post('/eps/factories', params)
}

export function editFactory(params) {
  return fetch.put('/eps/factories', params)
}
// 创建工厂单
export function createFactoryOrder(params) {
  return fetch.post('/eps/factory/order', params)
}
// 编辑工厂单
export function editFactoryOrder(params) {
  return fetch.put('/eps/factory/order', params)
}
// 查询工厂单
export function queryFactoryOrder(params): any {
  return fetch.post('/eps/factory/order/page', params)
}
// 工厂单刷新毛利率
export function refreshFactoryOrderGross() {
  return fetch.post('/eps/factory/order/refresh/gross/margin', {})
}
// 工厂单详情
export function getFactoryOrderDetail(params): any {
  return fetch.get('/eps/factory/order/info', { params })
}
// 批量查工单商品的工单详情
export function getBatchFactoryOrderDetail(params) {
  return fetch.post('/eps/factory/order/commodityIds', params)
}
// 删除取消工厂单
export function editFactoryOrderStatus(id, status) {
  return fetch.post('/eps/factory/order/update/status', {id, status})
}
// 审核工厂单，操作回货
export function auditFactoryOrder(id, auditStatus) {
  return fetch.post('/eps/factory/order/update/audit/status', { id, auditStatus })
}
// 批量审核
export function auditBatchFactoryOrder(ids,auditStatus) {
  return fetch.post('/eps/factory/order/batch/update/audit/status', { ids, auditStatus })
}
// 导出工厂单
export function exportFactoryOrder(params) {
  return fetch.post('/export/records/factory/order', params)
}
// 导入修改
export function importEditFactoryOrder(params) {
  return fetch.post('eps/factory/order/import/update', params)
}
// 导入工厂单
export function importFactoryOrder(params) {
  return fetch.post('/eps/factory/order/import', params)
}
// 工厂单审核人列表
export function getFactoryOrderReviewer(): any {
  return fetch.get('/staffs/factory/order/reviewer')
}
// 维修问题查询
export function getFactoryRepairQuestion(): any {
  return fetch.get('/repair/reason/type')
}
// 维修问题新增
export function addFactoryRepairQuestion(params): any {
  return fetch.post('/repair/reason/type', params)
}
// 维修问题编辑
export function editFactoryRepairQuestion(params): any {
  return fetch.put('/repair/reason/type', params)
}
// 维修问题删除
export function delFactoryRepairQuestion(id): any {
  return fetch.delete(`/repair/reason/type/${id}`)
}
// 维修问题排序
export function sortFactoryRepairQuestion(params): any {
  return fetch.put('/repair/reason/type/sort', params)
}
// 创建维修单
export function addRepariReasonTicket(params): any {
  return fetch.post('/repair/reason/ticket', params)
}
// 编辑维修单
export function editRepariReasonTicket(params): any {
  return fetch.put('/repair/reason/ticket', params)
}
// 查询维修单
export function getRepariReasonTicketList(params): any {
  return fetch.post('/repair/reason/ticket/page', params)
}
// 跟进维修单
export function followRepariReasonTicket(id): any {
  return fetch.put(`/repair/reason/ticket/follow/${id}`)
}
// 回货维修单
export function returnRepariReasonTicket(id): any {
  return fetch.put(`/repair/reason/ticket/return/${id}`)
}
// 取消维修单
export function cancelRepariReasonTicket(id): any {
  return fetch.put(`/repair/reason/ticket/cancel/${id}`)
}
// 完成维修单
export function finishRepariReasonTicket(id): any {
  return fetch.put(`/repair/reason/ticket/finish/${id}`)
}
// 获取工单商品石资料名称自定义
export function getFactoryOrderStoneCustom(): any {
  return fetch.get('/eps/factory/order/stone/name/custom')
}
// 编辑工单商品石资料名称自定义
export function editFactoryOrderStoneCustom(params): any {
  return fetch.post('/eps/factory/order/stone/name/custom', params)
}
// 工单进度批量编辑审核人
export function editBatchFactoryOrderReviewer(params): any {
  return fetch.post('/eps/factory/order/batch/update/reviewer', params)
}
// 工单进度批量取消  
export function cancelBatchFactoryOrder(params): any {
  return fetch.post('/eps/factory/order/batch/cancelled', params)
}
// 获取工单订单号
export function getFactoryOrderNo(): any {
  return fetch.get('/eps/factory/order/orderNo')
}
// 获取维修单号
export function getRepairOrderNo(): any {
  return fetch.get('/repair/reason/ticket/orderNo')
}
// 定制单补货出库
export function outputReplenishmentOrder(params): any {
  return fetch.post('/storage/outputs/batch/replenishment', params)
}
// 新的工单定制单批量出库
export function outputReplenishmentOrderNew(params): any {
  return fetch.post('/storage/outputs/new/batch/replenishment', params)
}
// 工单进度列表
export function getFactoryOrderProgress(): any {
  return fetch.get('/factory/order/progress')
}
// 新增工单进度
export function addFactoryOrderProgress(params): any {
  return fetch.post('/factory/order/progress', params)
}
// 编辑工单进度
export function editFactoryOrderProgress(params): any {
  return fetch.put('/factory/order/progress', params)
}
// 删除工单进度
export function delFactoryOrderProgress(code): any {
  return fetch.delete(`/factory/order/progress?code=${code}`)
}
// 订单数据分析
export function getFactoryOrderAnalysisList(params): any {
  return fetch.post('/eps/factory/order/analysis/report', params)
}
// 订单数据分析导出
export function exportFactoryOrderAnalysisList(params): any {
  return fetch.post('/export/records/eps/factory/order/analysis/report', params)
}
// 主石分析数据
export function getFactoryOrderMasterCatAnalysis(params): any {
  return fetch.post('/eps/factory/order/analysis/master/category/chart', params)
}
// 形状分析数据
export function getFactoryOrderMasterShapeAnalysis(params): any {
  return fetch.post('/eps/factory/order/analysis/shape/chart', params)
}
// 成色分析数据
export function getFactoryOrderMetalColorAnalysis(params): any {
  return fetch.post('/eps/factory/order/analysis/metal/color/chart', params)
}
// 款式数据
export function getFactoryOrderAnalysisDesign(params): any {
  return fetch.post('/eps/factory/order/analysis/design/report', params)
}
// 款式数据导出
export function exportFactoryOrderAnalysisDesign(params): any {
  return fetch.post('/export/records/eps/factory/order/analysis/design/report', params)
}
// 聚合属性名
export function getFeatureNameAlias(params): any {
  return fetch.get('/category/features/name/alias', { params })
}
// 综合分析 
export function getFactoryOrderAnalysisChart(params): any {
  return fetch.post('/eps/factory/order/analysis/chart', params)
}
// listing分析
export function getFactoryOrderListingAnalysisChart(params): any {
  return fetch.post('/eps/factory/order/analysis/listing/chart', params)
}
// 工单分析
export function getFactoryOrderAnalysisOptions(params): any {
  return fetch.post('/eps/factory/order/analytics/options', params)
}
// 批量修改工单标记
export function putFactoryOrderBatchFlagStatus(params): any {
  return fetch.put('/eps/factory/order/batch/update/flag/status', params)
}
// 导出设置查询
export function getExportColumnTemplate(type): any {
  return fetch.get(`/export/column/template?type=${type}`)
}
// 导出设置新增/编辑
export function postExportColumnTemplate(params): any {
  return fetch.post('/export/column/template', params)
}
// 导出设置删除
export function delExportColumnTemplate(id): any {
  return fetch.delete(`/export/column/template?id=${id}`)
}
// 批量编辑物流渠道
export function editBatchOrderLogisticsId(params): any {
  return fetch.post('/eps/factory/order/batch/update/logistics', params)
}
// 批量编辑小包裹物流渠道
export function editBatchOrderExpressId(ids, expressId): any {
  return fetch.put(`/eps/factory/order/batch/update/expressId?ids=${ids}&expressId=${expressId}`)
}
// 批量编辑物流单号
export function editBatchOrderLogisticsNo(params): any {
  return fetch.post('/eps/factory/order/batch/update/logisticsNo', params)
}
// 批量修改工单标签
export function editBatchFactoryOrderTags(params): any {
  return fetch.put('/eps/factory/order/batch/update/tag/status', params)
}
// 工单定制需求模板
export function addFactoryOrderCustomRemark(params): any {
  return fetch.post('/factory/order/custom/remark/template', params)
}

export function getFactoryOrderCustomRemark(params): any {
  return fetch.get('/factory/order/custom/remark/template', { params })
}

export function editFactoryOrderCustomRemark(params): any {
  return fetch.put('/factory/order/custom/remark/template', params)
}

export function delFactoryOrderCustomRemark(id): any {
  return fetch.delete(`/factory/order/custom/remark/template?id=${id}`)
}

// 维修进度导出
export function exportRepairTicket(params): any {
  return fetch.post('/export/records/repair/reason/ticket/report', params)
}

// 维修数据分析
export function exportRepairTicketAnalysis(params): any {
  return fetch.post('/repair/reason/ticket/analysisRepair', params)
}

// 导入工单石资料
export function importFactoryOrderStone(params): any {
  return fetch.post('/eps/factory/order/import/stone', params)
}
// 物流快递信息订阅借口
export function setFactoryOrderExpressSubscribe(params): any {
  return fetch.post('/express/subscribe', params)
}
// 查询物流快递最新信息
export function getFactoryOrderExpressNewInfo(params): any {
  return fetch.post('/express/query', params)
}
// 查询物流快递详细信息
export function getFactoryOrderExpressDetailInfo(params): any {
  return fetch.post('/express/detail/query', params)
}
// 石资料导出
export function exportFactoryOrderStone(params): any {
  return fetch.post('/export/records/order/stone', params)
}